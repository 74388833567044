import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CheckMark from '../images/product-assets/check-mark-filled.svg';
import { formatText } from '../utils';

const Container = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 560px) {
        padding: 0 4px;
    }
`;

const IconContainer = styled.div`
    border: 1px solid rgba(234, 236, 240, 1);
    border-radius: 8px;
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 560px) {
        height: 32px;
        width: 32px;
        border-radius: 5.82px;
    }
`;

const Icon = styled.img`
    @media only screen and (max-width: 560px) {
        width: 14.18px;
        height: 14.18px;
    }
`;

const Heading = styled.h3`
    font-size: 20px;
    font-weight: 500;
    line-height: 23.7px;
    color: rgba(38, 38, 38, 1);
    padding: 24px 0 12px 0;

    @media only screen and (max-width: 820px) {
        font-size: 16px;
        line-height: 18.96px;
    }
`;

const Description = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(71, 84, 103, 1);

    @media only screen and (max-width: 820px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const FirstWord = styled.span`
    font-weight: 500;
`;

const Ul = styled.ul`
    display: flex;
    flex-direction: column;
    padding-left: 0;
    list-style: none;
    padding-top: 8px;
    gap: 4px;
`;

const Li = styled.li`
    font-size: 16px;
    color: rgba(71, 84, 103, 1);
    line-height: 24px;
    list-style: none;
    background: url(${CheckMark}) no-repeat;
    background-position: 0 4px;
    padding-left: 30px;
    background-size: 20px 20px;

    @media only screen and (max-width: 820px) {
        background: none;
        list-style: disc;
        margin-left: 18px;
        list-style-position: outside;
        padding: 0;
        font-size: 12px;
        line-height: 16px;
    }
`;

const SecurityServiceContainer = ({ data }) => {
    return (
        <Container>
            <IconContainer>
                <Icon src={data.icon} alt="icon" />
            </IconContainer>
            <Heading>{data.title}</Heading>
            <Description>{formatText(data?.description)}</Description>
            {data.features && (
                <Ul>
                    {data.features.map((feature, index) => {
                        const words = feature.split(' ');
                        return (
                            <Li key={`${feature.slice(0, 20)}-${index}`}>
                                <FirstWord>{words[0]}</FirstWord> {words.slice(1).join(' ')}
                            </Li>
                        );
                    })}
                </Ul>
            )}
        </Container>
    );
};

SecurityServiceContainer.propTypes = {
    data: PropTypes.object,
};

export default SecurityServiceContainer;
