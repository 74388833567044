import React from 'react';
import styled, { css } from 'styled-components';
import { PropTypes } from 'prop-types';
import { Link } from 'gatsby';
import ArrowRightDark from '../../../images/product-assets/arrow-right-dark.svg';

const Container = styled.section`
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const Heading = styled.h1`
    font-size: 14px;
    font-weight: 400;
    line-height: 16.59px;
    color: #1d2939;
`;

const HeadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Icon = styled.div`
    height: 20px;
    width: 20px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        height: 1.5px;
        width: 100%;
        background-color: #000000;
        border-radius: 2px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease;
    }

    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 1.5px;
        border-radius: 2px;
        background-color: #000000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease;
    }

    ${(props) =>
        props.isActive &&
        css`
            &::before {
                transform: translate(-50%, -50%) rotate(90deg);
            }
        `}
`;
const ImageWrapper = styled.div`
    height: 209px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Image = styled.img`
    height: 240px;
    width: 240px;
    object-fit: contain;
`;

const Description = styled.p`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #4b5563;
`;

const ProductContent = styled.div`
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s linear;

    ${(props) =>
        props.isActive &&
        css`
            max-height: 403px;
        `}
`;

const ProductCard = styled.div`
    border: 1px solid #dddddd;
    padding: 20px 16px;
    border-radius: 12px;
    height: max-content;

    ${(props) =>
        props.isActive &&
        css`
            border: 1px solid #0c1a68;
            & > a {
                margin-top: 12px;
                display: flex;
                gap: 4px;
            }
            ${Heading} {
                font-weight: 500;
            }
        `}
`;

const Cta = styled(Link)`
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid rgba(191, 191, 191, 1);
    width: max-content;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10.25px;
    margin: 14px auto 0 auto;

    span {
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        color: #171717;
    }
`;
const ArrowIcon = styled.img`
    height: 18px;
    width: 18px;
    object-fit: contain;
`;
const ProductsDataMobile = ({ data, activeContent, toggleContent, activeTab }) => {
    return (
        <Container>
            {data.map((content, index) => (
                <ProductCard
                    key={`${content.heading}-${index}`}
                    onClick={() => toggleContent(index)}
                    isActive={activeContent === index}
                    activeTab={activeTab}
                >
                    <HeadingContainer>
                        <Heading>{content.heading}</Heading>
                        <Icon isActive={activeContent === index} />
                    </HeadingContainer>
                    <ProductContent isActive={activeContent === index}>
                        <ImageWrapper>
                            <Image src={content.image} draggable="false" />
                        </ImageWrapper>
                        {content?.description && <Description>{content.description}</Description>}
                        <Cta to={content.link}>
                            <span>Learn More</span>
                            <ArrowIcon src={ArrowRightDark} alt="arrow-right" />
                        </Cta>
                    </ProductContent>
                </ProductCard>
            ))}
        </Container>
    );
};

ProductsDataMobile.propTypes = {
    data: PropTypes.array,
    activeContent: PropTypes.number,
    toggleContent: PropTypes.func,
    activeTab: PropTypes.string,
};

export default ProductsDataMobile;
