// assets
import CCPA from '../../../images/logos/ccpa_logo.svg';
import GDPR from '../../../images/logos/gdpr_logo.svg';
import HIPAA from '../../../images/logos/hipaa_logo.svg';
import PCILogo from '../../../images/logos/pci_logo.svg';
import SOC from '../../../images/logos/soc_logo.svg';
import GLBALogo from '../../../images/logos/GLBA_logo.svg';
// icons - protection services
import Key from '../../../images/product-assets/key.svg';
import Cloud from '../../../images/product-assets/cloud.svg';
import Stack from '../../../images/product-assets/stack.svg';
import Shield from '../../../images/product-assets/protection-shield.svg';

import UserCardGray from '../../../images/product-assets/user-card-outlined-gray.svg';
import UserCardWhite from '../../../images/product-assets/user-card-outlined-white.svg';
import CheckMarkWhite from '../../../images/product-assets/check-mark-outlined-white.svg';
import CheckMarkGray from '../../../images/product-assets/check-mark-outlined-gray.svg';
// flagship solutions
import TCM from '../../../images/product-assets/tcm-illustration.svg';
import ReAuthenticate from '../../../images/product-assets/reautenticate-illustration.svg';
// Identity & Background Verifications
import FacialDetection from '../../../images/product-assets/ibv-facial-detection.svg';
import KBA from '../../../images/product-assets/ibv-kba.svg';
import GovtID from '../../../images/product-assets/ibv-govt.svg';
import CBC from '../../../images/product-assets/ibv-cbc.svg';
import BackgroundVerification from '../../../images/product-assets/ibv-background-verification.svg';

// compliance measures block - hover icons
import CheckIcon from '../../../images/product-assets/check-mark-round.svg';
import Card from '../../../images/product-assets/card-blue.svg';
import Clipboard from '../../../images/product-assets/clipboard-blue.svg';
import ShieldBlue from '../../../images/product-assets/shield-blue.svg';

// Security and Compliance: compliance measures data
export const complianceMeasuresData = [
    {
        title: 'PROTECTING SENSITIVE USER INFORMATION',
        heading: 'SOC 2 Type 2 and HIPAA Compliance',
        icon: CheckIcon,
        items: [
            {
                logo: SOC,
                heading: 'SOC 2 Type 2 Compliance',
                description:
                    'Our commitment to SOC 2 Type 2 standards involves thorough security measures that protect sensitive information from unauthorized access, ensuring reliability and integrity in managing client data.',
            },
            {
                logo: HIPAA,
                heading: 'HIPAA Compliance',
                description:
                    'We rigorously enforce HIPAA standards to safeguard sensitive data through comprehensive security protocols, maintaining the privacy and security of information.',
            },
        ],
    },
    {
        title: 'ENSURING DATA PROTECTION AND PRIVACY',
        heading: 'GDPR and CCPA Compliance',
        icon: ShieldBlue,
        items: [
            {
                logo: GDPR,
                heading: 'GDPR Compliance',
                description:
                    'The GDPR is an EU law on data protection and privacy. Authenticate® ensures compliance by implementing strict data protection measures, obtaining user consent, providing data transparency, and enabling user rights such as data access and erasure.',
            },
            {
                logo: CCPA,
                heading: 'CCPA Compliance',
                description:
                    'The California Consumer Privacy Act (CCPA) enhances privacy rights for California residents. Authenticate® complies by ensuring data transparency, offering opt-out mechanisms, and enabling data deletion requests.',
            },
        ],
    },
    {
        title: 'IMPORTANCE OF ETHICAL DATA USE',
        heading: 'DPPA and GLBA Compliance',
        icon: Clipboard,
        items: [
            {
                logo: GLBALogo,
                heading: 'DPPA and GLBA Compliance',
                description:
                    'Our compliance with DPPA, and GLBA underscores our commitment to ethical data use, ensuring the privacy and accuracy of consumer information.',
            },
        ],
    },
    {
        title: 'SAFEGUARDING CREDIT CARD INFORMATION',
        heading: 'PCI DSS Compliance',
        icon: Card,
        items: [
            {
                logo: PCILogo,
                heading: 'PCI DSS Compliance',
                description: `Authenticate® adheres to PCI DSS requirements with strict security controls and monitoring to protect cardholder data, demonstrating our dedication to maintaining a trusted payment environment.`,
            },
        ],
    },
];

// Security and Compliance: Security Services data
export const dataSecurityServices = [
    {
        title: 'Role-Based Access Control (RBAC)',
        icon: Key,
        description:
            'Authenticate® uses a three-tier RBAC system to ensure access is granted according to user roles.',
        features: [
            'Owners have full access, including billing information.',
            "Admins can run checks and configure workflows but can't access billing or account management.",
            'Viewers are limited to searching and viewing report only.',
        ],
    },
    {
        title: 'Cloud Backup',
        icon: Cloud,
        description:
            'Authenticate® partners with AWS for secure and encrypted data backups. Automated scheduling ensures regular backups, while geographically distributed storage enhances disaster recovery. Regular audits verify compliance with industry security standards.',
    },
    {
        title: 'Data Encryption',
        icon: Stack,
        description:
            'All customer data at Authenticate® is encrypted. Data at REST is protected using AES-256 encryption, and data in transit is secured with TLS. Sensitive elements, such as access tokens and keys, are encrypted at the application level before storage.',
    },
    {
        title: 'DDoS Protection',
        icon: Shield,
        description: `We protect against DDoS attacks with rate limiting, IP blacklisting and whitelisting, along with continuous monitoring and adaptive security measures.`,
    },
];

export const clickerTabsData = [
    {
        title: 'Identity & Background Verifications',
        iconDefault: UserCardGray,
        iconHover: UserCardWhite,
    },
    {
        title: 'Flagship Solutions',
        iconDefault: CheckMarkGray,
        iconHover: CheckMarkWhite,
    },
];

export const flahShipSolutions = [
    {
        heading: 'True Continuous Monitoring™',
        description:
            'Track users continuously for any new arrests, warrants, jail bookings, updates on sanctions or money-laundering alerts, and changes in license status like suspensions or revocations.',
        image: TCM,
        link: '/products/tcm',
    },
    {
        heading: 'ReAuthenticate™',
        description: `Simplify the onboarding and KYC process with ReAuthenticate™, a straightforward SaaS solution that transforms a typically cumbersome process into a smooth user experience. Plus, it's free!`,
        image: ReAuthenticate,
        link: '/products/reauthenticate',
    },
];

export const identityAndBackgroundVerifications = [
    {
        heading: 'Facial & Liveness Detection',
        image: FacialDetection,
        link: '/products/identity',
    },
    {
        heading: 'Knowledge-Based Authentication',
        image: KBA,
        link: '/products/identity',
    },
    {
        heading: 'Government ID Verification',
        image: GovtID,
        link: '/products/identity',
    },
    {
        heading: 'Criminal Background Checks',
        image: CBC,
        link: '/products/criminal-background-checks',
    },
    {
        heading: 'Background Verifications',
        image: BackgroundVerification,
        link: '/products/background-check',
    },
];
