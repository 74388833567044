import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

const Main = styled.div`
    width: 100%;
    padding: 38px 0 68px 0;

    @media only screen and (max-width: 560px) {
        padding: 20px 4px 32px 4px;
    }

    @media only screen and (max-width: 400px) {
        padding: 20px 20px 32px 20px;
    }
`;
const Container = styled.div`
    max-width: max-content;
    background: linear-gradient(105deg, #e0eeff -20.97%, rgba(157, 202, 255, 0.53) 141.2%);
    padding: 16px 26px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 0 auto;

    @media screen and (max-width: 820px) {
        padding: 12px 16px;
    }

    @media screen and (max-width: 560px) {
        background: none;
        padding: 0 0;
        flex-direction: row-reverse;
        gap: 4px;
    }
`;

const Button = styled.div`
    position: relative;
    padding: 8px 24px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    background: ${({ active }) => (active ? '#0C1A68' : '#ffffff')};
    border-radius: 24px;
    max-width: max-content;
    cursor: pointer;
    color: ${({ active }) => (active ? '#ffffff' : '#475467')};
    overflow: hidden;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #0c1a68;
        z-index: -1;
        transition: transform 0.3s ease-in-out;
        transform: ${({ active }) => (active ? 'translateX(0)' : 'translateX(100%)')};
    }

    ${({ active }) =>
        active &&
        `
        &:before {
            transform: translateX(0);
        }
    `}

    @media screen and (max-width: 560px) {
        padding: 8px 12px;
        background: ${({ active }) => (active ? '#0C1A68' : '#F2F4F7')};
    }

    @media only screen and (max-width: 375px) {
        padding: 6px 10px;
        gap: 6px;
    }
`;

const Icon = styled.img`
    height: 24px;
    width: 24px;

    @media screen and (max-width: 820px) {
        height: 20px;
        width: 20px;
    }

    @media screen and (max-width: 560px) {
        height: 16px;
        width: 16px;
    }
`;
const Heading = styled.p`
    font-size: 16px;
    font-weight: 400;
    @media screen and (max-width: 820px) {
        font-size: 14px;
    }

    @media screen and (max-width: 560px) {
        font-size: 12px;
    }

    @media only screen and (max-width: 400px) {
        font-size: 11px;
    }
`;
const Clicker = ({ data, activeTab, handleTabClick }) => {
    return (
        <Main>
            <Container>
                {data.map((tab, index) => (
                    <Button
                        key={`${tab.title}-${index}`}
                        type="button"
                        active={activeTab === index}
                        onClick={() => handleTabClick(index)}
                    >
                        <Icon
                            src={activeTab === index ? tab.iconHover : tab.iconDefault}
                            alt="user-card-icon"
                        />
                        <Heading>{tab.title}</Heading>
                    </Button>
                ))}
            </Container>
        </Main>
    );
};

Clicker.propTypes = {
    data: PropTypes.array.isRequired,
    activeTab: PropTypes.number.isRequired,
    handleTabClick: PropTypes.func.isRequired,
};

export default Clicker;
