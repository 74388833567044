import React from 'react';
import styled from 'styled-components';
import ArrowRight from '../../../images/product-assets/arrow-right-white.svg';
import { dataSecurityServices } from '../constants/constant.js';
import SecurityServiceContainer from '../../../components/SecurityServiceContainer.jsx';

const Container = styled.section``;

const Main = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: minmax(0, 301px) 1fr;
    gap: 52px;
    padding: 44px 28px 84px 28px;

    @media only screen and (max-width: 820px) {
        grid-template-columns: minmax(0, 192px) 1fr;
        gap: 36px;
    }

    @media only screen and (max-width: 560px) {
        grid-template-columns: minmax(0, 1fr);
        padding: 20px 16px 44px 16px;
    }
`;

const LeftContents = styled.div`
    display: flex;
    justify-content: flex-start;
    max-width: 100%;

    @media only screen and (max-width: 560px) {
        margin: 0 auto;
    }
`;

const Contents = styled.div`
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 560px) {
        align-items: center;
        max-width: 294px;
    }
`;

const Heading = styled.h1`
    font-size: 42px;
    font-weight: 500;
    line-height: 49.97px;
    color: rgba(0, 0, 0, 1);
    padding-right: 20px;

    @media screen and (max-width: 820px) {
        font-size: 32px;
        line-height: 37.92px;
    }

    @media only screen and (max-width: 560px) {
        font-size: 24px;
        padding-right: 0;
        text-align: center;
    }
`;

const SubText = styled.p`
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: rgba(82, 82, 91, 1);
    padding: 16px 0 24px 0;

    @media only screen and (max-width: 820px) {
        font-size: 12px;
        line-height: 16px;
    }

    @media only screen and (max-width: 560px) {
        text-align: center;
        padding: 12px 0 16px 0;
    }
`;

const ArrowIcon = styled.img`
    height: 17px;
    width: 17px;

    @media only screen and (max-width: 560px) {
        width: 13.5px;
        height: 10.5px;
    }
`;

const ExternalLink = styled.a`
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 8px 16px;
    background: rgba(12, 26, 104, 1);
    width: max-content;
    text-decoration: none;
    color: #fff;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;
    font-weight: 500;

    ${ArrowIcon} {
        transition: all 0.2s ease-in;
    }

    &:hover ${ArrowIcon} {
        transform: translateX(5px);
    }

    @media only screen and (max-width: 820px) {
        &:hover ${ArrowIcon} {
            transform: translateX(0);
        }
    }
`;

const RightContents = styled.div`
    max-width: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 44px;

    @media only screen and (max-width: 820px) {
        gap: 24px;
    }
    @media only screen and (max-width: 560px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;
const SecurityMeasures = () => {
    return (
        <Container>
            <Main>
                <LeftContents>
                    <Contents>
                        <Heading>In Security We Trust</Heading>
                        <SubText>
                            At Authenticate<sup>®</sup>, trust is founded on robust compliance and
                            privacy.
                        </SubText>
                        <ExternalLink
                            target="_blank"
                            rel="noreferrer"
                            href="https://info.authenticate.com/security"
                        >
                            <span>Learn More</span>
                            <ArrowIcon src={ArrowRight} alt="arrow-right" height={17} width={17} />
                        </ExternalLink>
                    </Contents>
                </LeftContents>
                <RightContents>
                    {dataSecurityServices.map((item, index) => (
                        <SecurityServiceContainer key={`${item.title}-${index}`} data={item} />
                    ))}
                </RightContents>
            </Main>
        </Container>
    );
};

export default SecurityMeasures;
