import React, { useState } from 'react';
import styled from 'styled-components';
import Clicker from '../components/Clicker';
import ProductsData from '../components/ProductsData';
import { useMediaQuery } from '@mui/material';
import {
    clickerTabsData,
    flahShipSolutions,
    identityAndBackgroundVerifications,
} from '../constants/constant';
import ProductsDataMobile from '../components/ProductsDataMobile';

const Container = styled.section``;

const MainContent = styled.div`
    max-width: 1280px;
    margin: 0 auto 56px auto;
`;

const Heading = styled.h1`
    font-size: 42px;
    font-weight: 500;
    line-height: 49.77px;
    text-align: center;
    color: #1d2939;

    @media only screen and (max-width: 820px) {
        font-size: 32px;
        line-height: 37.92px;
    }

    @media only screen and (max-width: 560px) {
        font-size: 24px;
        line-height: 28.44px;
    }
`;

const ProductsContainer = styled.div``;

const HighlightProducts = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [activeData, setActiveData] = useState(0);
    const isSmallScreen = useMediaQuery('(max-width: 560px)');

    const handleTabClick = (index) => {
        setActiveTab(index);
        setActiveData(0);
    };
    const handleToggleContent = (index) => {
        if (activeData === index) {
            setActiveData(0);
        } else {
            setActiveData(index);
        }
    };
    const contents = [identityAndBackgroundVerifications, flahShipSolutions];

    return (
        <Container>
            <MainContent>
                <Heading>Our Products</Heading>
                <Clicker
                    data={clickerTabsData}
                    activeTab={activeTab}
                    handleTabClick={handleTabClick}
                />
                <ProductsContainer>
                    {isSmallScreen ? (
                        <ProductsDataMobile
                            data={contents[activeTab]}
                            activeContent={activeData}
                            toggleContent={handleToggleContent}
                            activeTab={clickerTabsData[activeTab].title}
                        />
                    ) : (
                        <ProductsData
                            data={contents[activeTab]}
                            activeContent={activeData}
                            toggleContent={handleToggleContent}
                            activeTab={clickerTabsData[activeTab].title}
                        />
                    )}
                </ProductsContainer>
            </MainContent>
        </Container>
    );
};

export default HighlightProducts;
