import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ArrowRight from '../../../images/product-assets/arrow-right-white.svg';
import { PropTypes } from 'prop-types';

const ArrowIcon = styled.img`
    height: 17px;
    width: 17px;

    @media only screen and (max-width: 820px) {
        height: 16px;
        width: 16px;
    }

    @media only screen and (max-width: 560px) {
        width: 13.5px;
        height: 10.5px;
    }
`;

const StyledLink = styled(Link)`
    display: none;
    gap: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 8px 16px;
    background: rgba(12, 26, 104, 1);
    width: max-content;
    text-decoration: none;
    color: #fff;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;
    font-weight: 500;
    white-space: nowrap;

    ${ArrowIcon} {
        transition: transform 0.2s ease-in;
    }

    &:hover ${ArrowIcon} {
        transform: translateX(5px);
    }

    @media only screen and (max-width: 820px) {
        &:hover ${ArrowIcon} {
            transform: translateX(0);
        }
    }

    @media only screen and (max-width: 560px) {
        margin: 0 auto;
        padding: 4px 8px;
    }
`;
const ExternalLink = ({ label = 'Learn More', link }) => {
    return (
        <StyledLink to={link}>
            <span>{label}</span>
            <ArrowIcon src={ArrowRight} alt="arrow-right" height={17} width={17} />
        </StyledLink>
    );
};

ExternalLink.propTypes = {
    label: PropTypes.string,
    link: PropTypes.string,
};

export default ExternalLink;
