import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function MetaTagsProvider({
    title,
    description,
    keywords,
    ogImage,
    ogTitle,
    path,
    ogDescription,
    twitterTitle,
    twitterDescription,
}) {
    return (
        <Helmet>
            {/* Primary tags */}
            <title>{title}</title>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content={description} />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="robots" content="index, follow, archive" />
            <meta name="keywords" content={keywords} />
            <meta
                property="st:section"
                content="Identity Authentication & Background Check API & SDK for Custom Multi-Factor Verifications."
            />

            {/* OG / Facebook tags */}
            <meta property="og:title" content={ogTitle ?? title} />
            <meta property="og:description" content={ogDescription ?? description} />
            <meta property="og:image" content={ogImage} />
            <meta property="og:url" content={`https://authenticate.com/${path}`} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta
                property="og:site_name"
                content="Identity Authentication, ID Verification & Background Checks"
            />

            {/* Twitter tags */}
            <meta name="twitter:title" content={twitterTitle ?? title} />
            <meta name="twitter:description" content={twitterDescription ?? description} />
            <meta name="twitter:card" content="summary_large_image" />

            <link
                rel="shortcut icon"
                href={'https://u.jimcdn.com/cms/o/s27d67650be169598/img/favicon.png?t=1498234019'}
                type="image/x-icon"
            />
            <link rel="canonical" href={`https://authenticate.com/${path}`} />
        </Helmet>
    );
}

MetaTagsProvider.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
    ogImage: PropTypes.string,
    ogTitle: PropTypes.string,
    path: PropTypes.string,
    ogDescription: PropTypes.string,
    twitterTitle: PropTypes.string,
    twitterDescription: PropTypes.string,
};

export default MetaTagsProvider;
