import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints } from '../../../styles/screenSizes';
import { useModal } from '../../../context/ModalContext';

const Container = styled.section`
    max-width: 1280px;
    margin: 0 auto;
    padding: 44px 30px;

    @media only screen and (max-width: ${breakpoints.sm}) {
        padding: 20px 30px;
    }
`;

const Content = styled.div`
    padding: 30px 0;
    box-shadow: 0px -2px 24px 0px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    @media only screen and (max-width: ${breakpoints.sm}) {
        padding: 30px;
    }
`;

const Heading = styled.h1`
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
    text-transform: capitalize;
    color: #154683;
    max-width: 550px;
    text-align: center;
    margin: auto;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 30px;
        line-height: 38px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        font-size: 24px;
        line-height: 32px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 30px;

    @media only screen and (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        width: 100%;
        padding: 0 10px;
    }
`;
const SharedStyle = css`
    font-family: 'Rubik', sans-serif;
    padding: 8px 16px;
    border-radius: 4px;
    background: ${({ backgroundType }) => (backgroundType === 'light' ? '#fff' : '#154683')};
    color: ${({ backgroundType }) => (backgroundType === 'light' ? '#154683' : '#fff')};
    font-weight: 500;
    font-size: 14px;
    border: 1px solid #154683;
    cursor: pointer;
    text-decoration: none;

    @media only screen and (max-width: ${breakpoints.sm}) {
        width: 100%;
        text-align: center;
    }
`;
const StyledLink = styled.a`
    ${SharedStyle}
`;

const Button = styled.button`
    ${SharedStyle}
`;

const Circle = styled.div`
    &::before {
        content: '';
        position: absolute;
        width: 251px;
        height: 251px;
        background-color: #0058c6;
        border-radius: 50%;
        ${(props) =>
            props.position === 'topLeft'
                ? css`
                      top: -194px;
                      left: -114px;
                  `
                : css`
                      bottom: -163px;
                      right: -106px;
                  `}
    }

    &::after {
        content: '';
        position: absolute;
        width: 117px;
        height: 117px;
        border: 3px solid #0058c6;
        border-radius: 50%;
        ${(props) =>
            props.position === 'topLeft'
                ? css`
                      top: 3px;
                      left: -87px;
                  `
                : css`
                      top: 76px;
                      right: -71px;
                  `}
    }

    @media screen and (max-width: ${breakpoints.md}) {
        display: none;
    }
`;

const SecurityFrameworkLabel = () => {
    const { openModal } = useModal();
    return (
        <Container>
            <Content>
                <Circle position="topLeft" />
                <Circle position="bottomRight" />
                <Heading>Explore Our Robust Compliance & Security Framework</Heading>
                <ButtonContainer>
                    <Button type="button" onClick={() => openModal('contact')}>
                        Request Compliance Certificates
                    </Button>
                    <StyledLink
                        backgroundType="light"
                        href="https://info.authenticate.com/"
                        target="_blank"
                    >
                        Explore Knowledge Base
                    </StyledLink>
                </ButtonContainer>
            </Content>
        </Container>
    );
};

export default SecurityFrameworkLabel;
