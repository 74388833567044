import React from 'react';
import styled, { keyframes } from 'styled-components';
import ExternalLink from './ExternalLink';
import { PropTypes } from 'prop-types';

const slideIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const Container = styled.div`
    width: 100%;
    height: max-content;
`;

const Data = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 28px;
    padding: 0 28px;
    place-items: center;
`;

const ImageContainer = styled.div`
    border-radius: 32px;
    box-shadow: 0px 2px 27.3px 0px #00000014;
    position: relative;
    height: 440px;
    width: 100%;
    overflow: hidden;

    @media only screen and (max-width: 820px) {
        height: 254px;
    }
`;

const ImageWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${(props) => (props.isActive ? slideIn : slideOut)} 0.5s forwards;
    opacity: ${(props) => (props.isActive ? 1 : 0)};
    transition: opacity 0.5s;
`;

const Image = styled.img`
    height: 402px;
    width: 402px;
    object-fit: contain;

    @media only screen and (max-width: 820px) {
        height: 232.65px;
        width: 232.65px;
    }
`;

const Heading = styled.h1`
    font-size: 20px;
    line-height: 23.7px;
    color: #1d2939;
    font-weight: 400;

    @media only screen and (max-width: 820px) {
        font-size: 16px;
        line-height: 16.59px;
    }
`;

const ProductsOverview = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    width: 100%;
`;

const ProductCard = styled.div`
    padding: 20px 24px;
    border-radius: 16px;
    border: 1px solid #dddddd;
    height: max-content;
    width: 100%;
    transition: border 0.4s ease-in-out;
    ${(props) =>
        props.activeTab === 'Identity & Background Verifications' &&
        `   display: flex;
            align-items: center;
            justify-content: space-between;

            @media only screen and (max-width: 820px) {
            gap: 16px;
            }
         `}

    ${(props) =>
        props.isActive &&
        ` border: 1px solid #0c1a68;
         & > a {
            display: flex;
         }
         ${Heading}{
            font-weight: 500;
         }
    `}

@media only screen and (max-width: 820px) {
        padding: 20px 16px;
    }
`;

const Description = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 18.96px;
    padding: 14px 0;
    color: #4b5563;

    @media only screen and (max-width: 820px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const ProductsData = ({ data, activeContent, toggleContent, activeTab }) => {
    return (
        <Container>
            <Data>
                <ImageContainer>
                    {data.map((content, index) => (
                        <ImageWrapper
                            key={`${content.heading}-${index}`}
                            isActive={activeContent === index}
                        >
                            <Image src={content.image} alt={content.heading} draggable="false" />
                        </ImageWrapper>
                    ))}
                </ImageContainer>
                <ProductsOverview>
                    {data.map((content, index) => (
                        <ProductCard
                            key={`${content.heading}-${index}`}
                            onMouseEnter={() => toggleContent(index)}
                            isActive={activeContent === index}
                            activeTab={activeTab}
                        >
                            <Heading>{content.heading}</Heading>
                            {content?.description && (
                                <Description>{content.description}</Description>
                            )}
                            <ExternalLink link={content.link} />
                        </ProductCard>
                    ))}
                </ProductsOverview>
            </Data>
        </Container>
    );
};

ProductsData.propTypes = {
    data: PropTypes.array.isRequired,
    activeContent: PropTypes.number.isRequired,
    toggleContent: PropTypes.func.isRequired,
    activeTab: PropTypes.string.isRequired,
};

export default ProductsData;
